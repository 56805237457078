const Icons_couple = (props) => (
	<svg {...props} width="28" height="13" viewBox="0 0 28 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="21.4277" y="0.707107" width="3.22824" height="3.22824" transform="rotate(45 21.4277 0.707107)" stroke="white"/>
		<rect x="24.8662" y="4.14558" width="3.22824" height="3.22824" transform="rotate(45 24.8662 4.14558)" stroke="white"/>
		<rect x="17.9893" y="4.14558" width="3.22824" height="3.22824" transform="rotate(45 17.9893 4.14558)" stroke="white"/>
		<rect x="21.4277" y="7.58382" width="3.22824" height="3.22824" transform="rotate(45 21.4277 7.58382)" stroke="white"/>
		<rect x="6.42773" y="1.41421" width="2.22824" height="2.22824" transform="rotate(45 6.42773 1.41421)" stroke="white" stroke-width="2"/>
		<rect x="9.86621" y="4.85269" width="2.22824" height="2.22824" transform="rotate(45 9.86621 4.85269)" stroke="white" stroke-width="2"/>
		<rect x="2.98926" y="4.85269" width="2.22824" height="2.22824" transform="rotate(45 2.98926 4.85269)" stroke="white" stroke-width="2"/>
		<rect x="6.42773" y="8.29092" width="2.22824" height="2.22824" transform="rotate(45 6.42773 8.29092)" stroke="white" stroke-width="2"/>
	</svg>
);

export default Icons_couple;