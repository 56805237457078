const Qr_code_black = (props) => (
	<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} >
		<path d="M7.5661 0H6.70312V0.862973H7.5661V0Z" fill="#1E1E1E"/>
		<path d="M8.40399 0H7.54102V0.862973H8.40399V0Z" fill="#1E1E1E"/>
		<path d="M9.2409 0H8.37793V0.862973H9.2409V0Z" fill="#1E1E1E"/>
		<path d="M11.7546 0H10.8916V0.862973H11.7546V0Z" fill="#1E1E1E"/>
		<path d="M13.4304 0H12.5674V0.862973H13.4304V0Z" fill="#1E1E1E"/>
		<path d="M14.2682 0H13.4053V0.862973H14.2682V0Z" fill="#1E1E1E"/>
		<path d="M15.1061 0H14.2432V0.862973H15.1061V0Z" fill="#1E1E1E"/>
		<path d="M18.4577 0H17.5947V0.862973H18.4577V0Z" fill="#1E1E1E"/>
		<path d="M20.1335 0H19.2705V0.862973H20.1335V0Z" fill="#1E1E1E"/>
		<path d="M20.9714 0H20.1084V0.862973H20.9714V0Z" fill="#1E1E1E"/>
		<path d="M7.5661 0.837891H6.70312V1.70086H7.5661V0.837891Z" fill="#1E1E1E"/>
		<path d="M9.2409 0.837891H8.37793V1.70086H9.2409V0.837891Z" fill="#1E1E1E"/>
		<path d="M10.9167 0.837891H10.0537V1.70086H10.9167V0.837891Z" fill="#1E1E1E"/>
		<path d="M11.7546 0.837891H10.8916V1.70086H11.7546V0.837891Z" fill="#1E1E1E"/>
		<path d="M14.2682 0.837891H13.4053V1.70086H14.2682V0.837891Z" fill="#1E1E1E"/>
		<path d="M15.944 0.837891H15.0811V1.70086H15.944V0.837891Z" fill="#1E1E1E"/>
		<path d="M18.4577 0.837891H17.5947V1.70086H18.4577V0.837891Z" fill="#1E1E1E"/>
		<path d="M19.2956 0.837891H18.4326V1.70086H19.2956V0.837891Z" fill="#1E1E1E"/>
		<path d="M20.9714 0.837891H20.1084V1.70086H20.9714V0.837891Z" fill="#1E1E1E"/>
		<path d="M7.5661 1.67578H6.70312V2.53875H7.5661V1.67578Z" fill="#1E1E1E"/>
		<path d="M10.9167 1.67578H10.0537V2.53875H10.9167V1.67578Z" fill="#1E1E1E"/>
		<path d="M13.4304 1.67578H12.5674V2.53875H13.4304V1.67578Z" fill="#1E1E1E"/>
		<path d="M14.2682 1.67578H13.4053V2.53875H14.2682V1.67578Z" fill="#1E1E1E"/>
		<path d="M15.1061 1.67578H14.2432V2.53875H15.1061V1.67578Z" fill="#1E1E1E"/>
		<path d="M18.4577 1.67578H17.5947V2.53875H18.4577V1.67578Z" fill="#1E1E1E"/>
		<path d="M20.1335 1.67578H19.2705V2.53875H20.1335V1.67578Z" fill="#1E1E1E"/>
		<path d="M20.9714 1.67578H20.1084V2.53875H20.9714V1.67578Z" fill="#1E1E1E"/>
		<path d="M10.9167 2.51343H10.0537V3.3764H10.9167V2.51343Z" fill="#1E1E1E"/>
		<path d="M11.7546 2.51343H10.8916V3.3764H11.7546V2.51343Z" fill="#1E1E1E"/>
		<path d="M12.5925 2.51343H11.7295V3.3764H12.5925V2.51343Z" fill="#1E1E1E"/>
		<path d="M13.4304 2.51343H12.5674V3.3764H13.4304V2.51343Z" fill="#1E1E1E"/>
		<path d="M15.1061 2.51343H14.2432V3.3764H15.1061V2.51343Z" fill="#1E1E1E"/>
		<path d="M15.944 2.51343H15.0811V3.3764H15.944V2.51343Z" fill="#1E1E1E"/>
		<path d="M16.7819 2.51343H15.9189V3.3764H16.7819V2.51343Z" fill="#1E1E1E"/>
		<path d="M19.2956 2.51343H18.4326V3.3764H19.2956V2.51343Z" fill="#1E1E1E"/>
		<path d="M20.1335 2.51343H19.2705V3.3764H20.1335V2.51343Z" fill="#1E1E1E"/>
		<path d="M20.9714 2.51343H20.1084V3.3764H20.9714V2.51343Z" fill="#1E1E1E"/>
		<path d="M9.2409 3.35132H8.37793V4.21429H9.2409V3.35132Z" fill="#1E1E1E"/>
		<path d="M14.2682 3.35132H13.4053V4.21429H14.2682V3.35132Z" fill="#1E1E1E"/>
		<path d="M15.1061 3.35132H14.2432V4.21429H15.1061V3.35132Z" fill="#1E1E1E"/>
		<path d="M15.944 3.35132H15.0811V4.21429H15.944V3.35132Z" fill="#1E1E1E"/>
		<path d="M18.4577 3.35132H17.5947V4.21429H18.4577V3.35132Z" fill="#1E1E1E"/>
		<path d="M7.5661 4.18921H6.70312V5.05218H7.5661V4.18921Z" fill="#1E1E1E"/>
		<path d="M9.2409 4.18921H8.37793V5.05218H9.2409V4.18921Z" fill="#1E1E1E"/>
		<path d="M10.0788 4.18921H9.21582V5.05218H10.0788V4.18921Z" fill="#1E1E1E"/>
		<path d="M11.7546 4.18921H10.8916V5.05218H11.7546V4.18921Z" fill="#1E1E1E"/>
		<path d="M13.4304 4.18921H12.5674V5.05218H13.4304V4.18921Z" fill="#1E1E1E"/>
		<path d="M15.944 4.18921H15.0811V5.05218H15.944V4.18921Z" fill="#1E1E1E"/>
		<path d="M18.4577 4.18921H17.5947V5.05218H18.4577V4.18921Z" fill="#1E1E1E"/>
		<path d="M19.2956 4.18921H18.4326V5.05218H19.2956V4.18921Z" fill="#1E1E1E"/>
		<path d="M20.1335 4.18921H19.2705V5.05218H20.1335V4.18921Z" fill="#1E1E1E"/>
		<path d="M20.9714 4.18921H20.1084V5.05218H20.9714V4.18921Z" fill="#1E1E1E"/>
		<path d="M7.5661 5.0271H6.70312V5.89007H7.5661V5.0271Z" fill="#1E1E1E"/>
		<path d="M9.2409 5.0271H8.37793V5.89007H9.2409V5.0271Z" fill="#1E1E1E"/>
		<path d="M10.9167 5.0271H10.0537V5.89007H10.9167V5.0271Z" fill="#1E1E1E"/>
		<path d="M12.5925 5.0271H11.7295V5.89007H12.5925V5.0271Z" fill="#1E1E1E"/>
		<path d="M14.2682 5.0271H13.4053V5.89007H14.2682V5.0271Z" fill="#1E1E1E"/>
		<path d="M15.944 5.0271H15.0811V5.89007H15.944V5.0271Z" fill="#1E1E1E"/>
		<path d="M17.6198 5.0271H16.7568V5.89007H17.6198V5.0271Z" fill="#1E1E1E"/>
		<path d="M19.2956 5.0271H18.4326V5.89007H19.2956V5.0271Z" fill="#1E1E1E"/>
		<path d="M20.9714 5.0271H20.1084V5.89007H20.9714V5.0271Z" fill="#1E1E1E"/>
		<path d="M7.5661 5.86475H6.70312V6.72772H7.5661V5.86475Z" fill="#1E1E1E"/>
		<path d="M8.40399 5.86475H7.54102V6.72772H8.40399V5.86475Z" fill="#1E1E1E"/>
		<path d="M9.2409 5.86475H8.37793V6.72772H9.2409V5.86475Z" fill="#1E1E1E"/>
		<path d="M11.7546 5.86475H10.8916V6.72772H11.7546V5.86475Z" fill="#1E1E1E"/>
		<path d="M13.4304 5.86475H12.5674V6.72772H13.4304V5.86475Z" fill="#1E1E1E"/>
		<path d="M15.1061 5.86475H14.2432V6.72772H15.1061V5.86475Z" fill="#1E1E1E"/>
		<path d="M15.944 5.86475H15.0811V6.72772H15.944V5.86475Z" fill="#1E1E1E"/>
		<path d="M16.7819 5.86475H15.9189V6.72772H16.7819V5.86475Z" fill="#1E1E1E"/>
		<path d="M17.6198 5.86475H16.7568V6.72772H17.6198V5.86475Z" fill="#1E1E1E"/>
		<path d="M18.4577 5.86475H17.5947V6.72772H18.4577V5.86475Z" fill="#1E1E1E"/>
		<path d="M20.1335 5.86475H19.2705V6.72772H20.1335V5.86475Z" fill="#1E1E1E"/>
		<path d="M2.53875 6.70264H1.67578V7.56561H2.53875V6.70264Z" fill="#1E1E1E"/>
		<path d="M3.37664 6.70264H2.51367V7.56561H3.37664V6.70264Z" fill="#1E1E1E"/>
		<path d="M4.21454 6.70264H3.35156V7.56561H4.21454V6.70264Z" fill="#1E1E1E"/>
		<path d="M5.89032 6.70264H5.02734V7.56561H5.89032V6.70264Z" fill="#1E1E1E"/>
		<path d="M7.5661 6.70264H6.70312V7.56561H7.5661V6.70264Z" fill="#1E1E1E"/>
		<path d="M10.0788 6.70264H9.21582V7.56561H10.0788V6.70264Z" fill="#1E1E1E"/>
		<path d="M10.9167 6.70264H10.0537V7.56561H10.9167V6.70264Z" fill="#1E1E1E"/>
		<path d="M12.5925 6.70264H11.7295V7.56561H12.5925V6.70264Z" fill="#1E1E1E"/>
		<path d="M15.944 6.70264H15.0811V7.56561H15.944V6.70264Z" fill="#1E1E1E"/>
		<path d="M19.2956 6.70264H18.4326V7.56561H19.2956V6.70264Z" fill="#1E1E1E"/>
		<path d="M20.1335 6.70264H19.2705V7.56561H20.1335V6.70264Z" fill="#1E1E1E"/>
		<path d="M20.9714 6.70264H20.1084V7.56561H20.9714V6.70264Z" fill="#1E1E1E"/>
		<path d="M21.8093 6.70264H20.9463V7.56561H21.8093V6.70264Z" fill="#1E1E1E"/>
		<path d="M22.6472 6.70264H21.7842V7.56561H22.6472V6.70264Z" fill="#1E1E1E"/>
		<path d="M23.485 6.70264H22.6221V7.56561H23.485V6.70264Z" fill="#1E1E1E"/>
		<path d="M25.9977 6.70264H25.1348V7.56561H25.9977V6.70264Z" fill="#1E1E1E"/>
		<path d="M26.8356 6.70264H25.9727V7.56561H26.8356V6.70264Z" fill="#1E1E1E"/>
		<path d="M27.6735 6.70264H26.8105V7.56561H27.6735V6.70264Z" fill="#1E1E1E"/>
		<path d="M3.37664 7.54053H2.51367V8.4035H3.37664V7.54053Z" fill="#1E1E1E"/>
		<path d="M4.21454 7.54053H3.35156V8.4035H4.21454V7.54053Z" fill="#1E1E1E"/>
		<path d="M5.05243 7.54053H4.18945V8.4035H5.05243V7.54053Z" fill="#1E1E1E"/>
		<path d="M9.2409 7.54053H8.37793V8.4035H9.2409V7.54053Z" fill="#1E1E1E"/>
		<path d="M10.9167 7.54053H10.0537V8.4035H10.9167V7.54053Z" fill="#1E1E1E"/>
		<path d="M12.5925 7.54053H11.7295V8.4035H12.5925V7.54053Z" fill="#1E1E1E"/>
		<path d="M15.1061 7.54053H14.2432V8.4035H15.1061V7.54053Z" fill="#1E1E1E"/>
		<path d="M16.7819 7.54053H15.9189V8.4035H16.7819V7.54053Z" fill="#1E1E1E"/>
		<path d="M17.6198 7.54053H16.7568V8.4035H17.6198V7.54053Z" fill="#1E1E1E"/>
		<path d="M18.4577 7.54053H17.5947V8.4035H18.4577V7.54053Z" fill="#1E1E1E"/>
		<path d="M20.1335 7.54053H19.2705V8.4035H20.1335V7.54053Z" fill="#1E1E1E"/>
		<path d="M21.8093 7.54053H20.9463V8.4035H21.8093V7.54053Z" fill="#1E1E1E"/>
		<path d="M22.6472 7.54053H21.7842V8.4035H22.6472V7.54053Z" fill="#1E1E1E"/>
		<path d="M25.9977 7.54053H25.1348V8.4035H25.9977V7.54053Z" fill="#1E1E1E"/>
		<path d="M27.6735 7.54053H26.8105V8.4035H27.6735V7.54053Z" fill="#1E1E1E"/>
		<path d="M0.862973 8.37842H0V9.24139H0.862973V8.37842Z" fill="#1E1E1E"/>
		<path d="M2.53875 8.37842H1.67578V9.24139H2.53875V8.37842Z" fill="#1E1E1E"/>
		<path d="M3.37664 8.37842H2.51367V9.24139H3.37664V8.37842Z" fill="#1E1E1E"/>
		<path d="M4.21454 8.37842H3.35156V9.24139H4.21454V8.37842Z" fill="#1E1E1E"/>
		<path d="M5.05243 8.37842H4.18945V9.24139H5.05243V8.37842Z" fill="#1E1E1E"/>
		<path d="M5.89032 8.37842H5.02734V9.24139H5.89032V8.37842Z" fill="#1E1E1E"/>
		<path d="M7.5661 8.37842H6.70312V9.24139H7.5661V8.37842Z" fill="#1E1E1E"/>
		<path d="M9.2409 8.37842H8.37793V9.24139H9.2409V8.37842Z" fill="#1E1E1E"/>
		<path d="M10.9167 8.37842H10.0537V9.24139H10.9167V8.37842Z" fill="#1E1E1E"/>
		<path d="M12.5925 8.37842H11.7295V9.24139H12.5925V8.37842Z" fill="#1E1E1E"/>
		<path d="M15.944 8.37842H15.0811V9.24139H15.944V8.37842Z" fill="#1E1E1E"/>
		<path d="M18.4577 8.37842H17.5947V9.24139H18.4577V8.37842Z" fill="#1E1E1E"/>
		<path d="M23.485 8.37842H22.6221V9.24139H23.485V8.37842Z" fill="#1E1E1E"/>
		<path d="M26.8356 8.37842H25.9727V9.24139H26.8356V8.37842Z" fill="#1E1E1E"/>
		<path d="M2.53875 9.21631H1.67578V10.0793H2.53875V9.21631Z" fill="#1E1E1E"/>
		<path d="M3.37664 9.21631H2.51367V10.0793H3.37664V9.21631Z" fill="#1E1E1E"/>
		<path d="M5.05243 9.21631H4.18945V10.0793H5.05243V9.21631Z" fill="#1E1E1E"/>
		<path d="M6.72821 9.21631H5.86523V10.0793H6.72821V9.21631Z" fill="#1E1E1E"/>
		<path d="M7.5661 9.21631H6.70312V10.0793H7.5661V9.21631Z" fill="#1E1E1E"/>
		<path d="M10.0788 9.21631H9.21582V10.0793H10.0788V9.21631Z" fill="#1E1E1E"/>
		<path d="M10.9167 9.21631H10.0537V10.0793H10.9167V9.21631Z" fill="#1E1E1E"/>
		<path d="M13.4304 9.21631H12.5674V10.0793H13.4304V9.21631Z" fill="#1E1E1E"/>
		<path d="M14.2682 9.21631H13.4053V10.0793H14.2682V9.21631Z" fill="#1E1E1E"/>
		<path d="M15.944 9.21631H15.0811V10.0793H15.944V9.21631Z" fill="#1E1E1E"/>
		<path d="M16.7819 9.21631H15.9189V10.0793H16.7819V9.21631Z" fill="#1E1E1E"/>
		<path d="M17.6198 9.21631H16.7568V10.0793H17.6198V9.21631Z" fill="#1E1E1E"/>
		<path d="M18.4577 9.21631H17.5947V10.0793H18.4577V9.21631Z" fill="#1E1E1E"/>
		<path d="M19.2956 9.21631H18.4326V10.0793H19.2956V9.21631Z" fill="#1E1E1E"/>
		<path d="M25.1598 9.21631H24.2969V10.0793H25.1598V9.21631Z" fill="#1E1E1E"/>
		<path d="M25.9977 9.21631H25.1348V10.0793H25.9977V9.21631Z" fill="#1E1E1E"/>
		<path d="M26.8356 9.21631H25.9727V10.0793H26.8356V9.21631Z" fill="#1E1E1E"/>
		<path d="M27.6735 9.21631H26.8105V10.0793H27.6735V9.21631Z" fill="#1E1E1E"/>
		<path d="M0.862973 10.054H0V10.9169H0.862973V10.054Z" fill="#1E1E1E"/>
		<path d="M5.05243 10.054H4.18945V10.9169H5.05243V10.054Z" fill="#1E1E1E"/>
		<path d="M5.89032 10.054H5.02734V10.9169H5.89032V10.054Z" fill="#1E1E1E"/>
		<path d="M6.72821 10.054H5.86523V10.9169H6.72821V10.054Z" fill="#1E1E1E"/>
		<path d="M8.40399 10.054H7.54102V10.9169H8.40399V10.054Z" fill="#1E1E1E"/>
		<path d="M9.2409 10.054H8.37793V10.9169H9.2409V10.054Z" fill="#1E1E1E"/>
		<path d="M15.1061 10.054H14.2432V10.9169H15.1061V10.054Z" fill="#1E1E1E"/>
		<path d="M15.944 10.054H15.0811V10.9169H15.944V10.054Z" fill="#1E1E1E"/>
		<path d="M17.6198 10.054H16.7568V10.9169H17.6198V10.054Z" fill="#1E1E1E"/>
		<path d="M18.4577 10.054H17.5947V10.9169H18.4577V10.054Z" fill="#1E1E1E"/>
		<path d="M21.8093 10.054H20.9463V10.9169H21.8093V10.054Z" fill="#1E1E1E"/>
		<path d="M1.70086 10.8918H0.837891V11.7548H1.70086V10.8918Z" fill="#1E1E1E"/>
		<path d="M2.53875 10.8918H1.67578V11.7548H2.53875V10.8918Z" fill="#1E1E1E"/>
		<path d="M5.05243 10.8918H4.18945V11.7548H5.05243V10.8918Z" fill="#1E1E1E"/>
		<path d="M6.72821 10.8918H5.86523V11.7548H6.72821V10.8918Z" fill="#1E1E1E"/>
		<path d="M9.2409 10.8918H8.37793V11.7548H9.2409V10.8918Z" fill="#1E1E1E"/>
		<path d="M10.0788 10.8918H9.21582V11.7548H10.0788V10.8918Z" fill="#1E1E1E"/>
		<path d="M10.9167 10.8918H10.0537V11.7548H10.9167V10.8918Z" fill="#1E1E1E"/>
		<path d="M15.1061 10.8918H14.2432V11.7548H15.1061V10.8918Z" fill="#1E1E1E"/>
		<path d="M16.7819 10.8918H15.9189V11.7548H16.7819V10.8918Z" fill="#1E1E1E"/>
		<path d="M19.2956 10.8918H18.4326V11.7548H19.2956V10.8918Z" fill="#1E1E1E"/>
		<path d="M20.1335 10.8918H19.2705V11.7548H20.1335V10.8918Z" fill="#1E1E1E"/>
		<path d="M21.8093 10.8918H20.9463V11.7548H21.8093V10.8918Z" fill="#1E1E1E"/>
		<path d="M22.6472 10.8918H21.7842V11.7548H22.6472V10.8918Z" fill="#1E1E1E"/>
		<path d="M23.485 10.8918H22.6221V11.7548H23.485V10.8918Z" fill="#1E1E1E"/>
		<path d="M25.9977 10.8918H25.1348V11.7548H25.9977V10.8918Z" fill="#1E1E1E"/>
		<path d="M27.6735 10.8918H26.8105V11.7548H27.6735V10.8918Z" fill="#1E1E1E"/>
		<path d="M1.70086 11.7297H0.837891V12.5927H1.70086V11.7297Z" fill="#1E1E1E"/>
		<path d="M2.53875 11.7297H1.67578V12.5927H2.53875V11.7297Z" fill="#1E1E1E"/>
		<path d="M5.05243 11.7297H4.18945V12.5927H5.05243V11.7297Z" fill="#1E1E1E"/>
		<path d="M5.89032 11.7297H5.02734V12.5927H5.89032V11.7297Z" fill="#1E1E1E"/>
		<path d="M7.5661 11.7297H6.70312V12.5927H7.5661V11.7297Z" fill="#1E1E1E"/>
		<path d="M9.2409 11.7297H8.37793V12.5927H9.2409V11.7297Z" fill="#1E1E1E"/>
		<path d="M10.0788 11.7297H9.21582V12.5927H10.0788V11.7297Z" fill="#1E1E1E"/>
		<path d="M10.9167 11.7297H10.0537V12.5927H10.9167V11.7297Z" fill="#1E1E1E"/>
		<path d="M14.2682 11.7297H13.4053V12.5927H14.2682V11.7297Z" fill="#1E1E1E"/>
		<path d="M15.944 11.7297H15.0811V12.5927H15.944V11.7297Z" fill="#1E1E1E"/>
		<path d="M17.6198 11.7297H16.7568V12.5927H17.6198V11.7297Z" fill="#1E1E1E"/>
		<path d="M19.2956 11.7297H18.4326V12.5927H19.2956V11.7297Z" fill="#1E1E1E"/>
		<path d="M21.8093 11.7297H20.9463V12.5927H21.8093V11.7297Z" fill="#1E1E1E"/>
		<path d="M22.6472 11.7297H21.7842V12.5927H22.6472V11.7297Z" fill="#1E1E1E"/>
		<path d="M25.1598 11.7297H24.2969V12.5927H25.1598V11.7297Z" fill="#1E1E1E"/>
		<path d="M25.9977 11.7297H25.1348V12.5927H25.9977V11.7297Z" fill="#1E1E1E"/>
		<path d="M26.8356 11.7297H25.9727V12.5927H26.8356V11.7297Z" fill="#1E1E1E"/>
		<path d="M4.21454 12.5676H3.35156V13.4306H4.21454V12.5676Z" fill="#1E1E1E"/>
		<path d="M5.05243 12.5676H4.18945V13.4306H5.05243V12.5676Z" fill="#1E1E1E"/>
		<path d="M6.72821 12.5676H5.86523V13.4306H6.72821V12.5676Z" fill="#1E1E1E"/>
		<path d="M9.2409 12.5676H8.37793V13.4306H9.2409V12.5676Z" fill="#1E1E1E"/>
		<path d="M10.9167 12.5676H10.0537V13.4306H10.9167V12.5676Z" fill="#1E1E1E"/>
		<path d="M13.4304 12.5676H12.5674V13.4306H13.4304V12.5676Z" fill="#1E1E1E"/>
		<path d="M14.2682 12.5676H13.4053V13.4306H14.2682V12.5676Z" fill="#1E1E1E"/>
		<path d="M15.1061 12.5676H14.2432V13.4306H15.1061V12.5676Z" fill="#1E1E1E"/>
		<path d="M15.944 12.5676H15.0811V13.4306H15.944V12.5676Z" fill="#1E1E1E"/>
		<path d="M17.6198 12.5676H16.7568V13.4306H17.6198V12.5676Z" fill="#1E1E1E"/>
		<path d="M18.4577 12.5676H17.5947V13.4306H18.4577V12.5676Z" fill="#1E1E1E"/>
		<path d="M19.2956 12.5676H18.4326V13.4306H19.2956V12.5676Z" fill="#1E1E1E"/>
		<path d="M20.9714 12.5676H20.1084V13.4306H20.9714V12.5676Z" fill="#1E1E1E"/>
		<path d="M21.8093 12.5676H20.9463V13.4306H21.8093V12.5676Z" fill="#1E1E1E"/>
		<path d="M22.6472 12.5676H21.7842V13.4306H22.6472V12.5676Z" fill="#1E1E1E"/>
		<path d="M23.485 12.5676H22.6221V13.4306H23.485V12.5676Z" fill="#1E1E1E"/>
		<path d="M25.9977 12.5676H25.1348V13.4306H25.9977V12.5676Z" fill="#1E1E1E"/>
		<path d="M26.8356 12.5676H25.9727V13.4306H26.8356V12.5676Z" fill="#1E1E1E"/>
		<path d="M0.862973 13.4055H0V14.2685H0.862973V13.4055Z" fill="#1E1E1E"/>
		<path d="M1.70086 13.4055H0.837891V14.2685H1.70086V13.4055Z" fill="#1E1E1E"/>
		<path d="M2.53875 13.4055H1.67578V14.2685H2.53875V13.4055Z" fill="#1E1E1E"/>
		<path d="M5.05243 13.4055H4.18945V14.2685H5.05243V13.4055Z" fill="#1E1E1E"/>
		<path d="M5.89032 13.4055H5.02734V14.2685H5.89032V13.4055Z" fill="#1E1E1E"/>
		<path d="M6.72821 13.4055H5.86523V14.2685H6.72821V13.4055Z" fill="#1E1E1E"/>
		<path d="M11.7546 13.4055H10.8916V14.2685H11.7546V13.4055Z" fill="#1E1E1E"/>
		<path d="M14.2682 13.4055H13.4053V14.2685H14.2682V13.4055Z" fill="#1E1E1E"/>
		<path d="M15.944 13.4055H15.0811V14.2685H15.944V13.4055Z" fill="#1E1E1E"/>
		<path d="M20.1335 13.4055H19.2705V14.2685H20.1335V13.4055Z" fill="#1E1E1E"/>
		<path d="M21.8093 13.4055H20.9463V14.2685H21.8093V13.4055Z" fill="#1E1E1E"/>
		<path d="M23.485 13.4055H22.6221V14.2685H23.485V13.4055Z" fill="#1E1E1E"/>
		<path d="M24.322 13.4055H23.459V14.2685H24.322V13.4055Z" fill="#1E1E1E"/>
		<path d="M25.1598 13.4055H24.2969V14.2685H25.1598V13.4055Z" fill="#1E1E1E"/>
		<path d="M25.9977 13.4055H25.1348V14.2685H25.9977V13.4055Z" fill="#1E1E1E"/>
		<path d="M27.6735 13.4055H26.8105V14.2685H27.6735V13.4055Z" fill="#1E1E1E"/>
		<path d="M0.862973 14.2432H0V15.1061H0.862973V14.2432Z" fill="#1E1E1E"/>
		<path d="M2.53875 14.2432H1.67578V15.1061H2.53875V14.2432Z" fill="#1E1E1E"/>
		<path d="M4.21454 14.2432H3.35156V15.1061H4.21454V14.2432Z" fill="#1E1E1E"/>
		<path d="M6.72821 14.2432H5.86523V15.1061H6.72821V14.2432Z" fill="#1E1E1E"/>
		<path d="M7.5661 14.2432H6.70312V15.1061H7.5661V14.2432Z" fill="#1E1E1E"/>
		<path d="M8.40399 14.2432H7.54102V15.1061H8.40399V14.2432Z" fill="#1E1E1E"/>
		<path d="M9.2409 14.2432H8.37793V15.1061H9.2409V14.2432Z" fill="#1E1E1E"/>
		<path d="M10.0788 14.2432H9.21582V15.1061H10.0788V14.2432Z" fill="#1E1E1E"/>
		<path d="M10.9167 14.2432H10.0537V15.1061H10.9167V14.2432Z" fill="#1E1E1E"/>
		<path d="M12.5925 14.2432H11.7295V15.1061H12.5925V14.2432Z" fill="#1E1E1E"/>
		<path d="M16.7819 14.2432H15.9189V15.1061H16.7819V14.2432Z" fill="#1E1E1E"/>
		<path d="M17.6198 14.2432H16.7568V15.1061H17.6198V14.2432Z" fill="#1E1E1E"/>
		<path d="M18.4577 14.2432H17.5947V15.1061H18.4577V14.2432Z" fill="#1E1E1E"/>
		<path d="M20.1335 14.2432H19.2705V15.1061H20.1335V14.2432Z" fill="#1E1E1E"/>
		<path d="M20.9714 14.2432H20.1084V15.1061H20.9714V14.2432Z" fill="#1E1E1E"/>
		<path d="M22.6472 14.2432H21.7842V15.1061H22.6472V14.2432Z" fill="#1E1E1E"/>
		<path d="M23.485 14.2432H22.6221V15.1061H23.485V14.2432Z" fill="#1E1E1E"/>
		<path d="M1.70086 15.0811H0.837891V15.944H1.70086V15.0811Z" fill="#1E1E1E"/>
		<path d="M3.37664 15.0811H2.51367V15.944H3.37664V15.0811Z" fill="#1E1E1E"/>
		<path d="M4.21454 15.0811H3.35156V15.944H4.21454V15.0811Z" fill="#1E1E1E"/>
		<path d="M5.89032 15.0811H5.02734V15.944H5.89032V15.0811Z" fill="#1E1E1E"/>
		<path d="M9.2409 15.0811H8.37793V15.944H9.2409V15.0811Z" fill="#1E1E1E"/>
		<path d="M10.9167 15.0811H10.0537V15.944H10.9167V15.0811Z" fill="#1E1E1E"/>
		<path d="M12.5925 15.0811H11.7295V15.944H12.5925V15.0811Z" fill="#1E1E1E"/>
		<path d="M13.4304 15.0811H12.5674V15.944H13.4304V15.0811Z" fill="#1E1E1E"/>
		<path d="M14.2682 15.0811H13.4053V15.944H14.2682V15.0811Z" fill="#1E1E1E"/>
		<path d="M18.4577 15.0811H17.5947V15.944H18.4577V15.0811Z" fill="#1E1E1E"/>
		<path d="M19.2956 15.0811H18.4326V15.944H19.2956V15.0811Z" fill="#1E1E1E"/>
		<path d="M21.8093 15.0811H20.9463V15.944H21.8093V15.0811Z" fill="#1E1E1E"/>
		<path d="M22.6472 15.0811H21.7842V15.944H22.6472V15.0811Z" fill="#1E1E1E"/>
		<path d="M24.322 15.0811H23.459V15.944H24.322V15.0811Z" fill="#1E1E1E"/>
		<path d="M25.9977 15.0811H25.1348V15.944H25.9977V15.0811Z" fill="#1E1E1E"/>
		<path d="M26.8356 15.0811H25.9727V15.944H26.8356V15.0811Z" fill="#1E1E1E"/>
		<path d="M27.6735 15.0811H26.8105V15.944H27.6735V15.0811Z" fill="#1E1E1E"/>
		<path d="M1.70086 15.9189H0.837891V16.7819H1.70086V15.9189Z" fill="#1E1E1E"/>
		<path d="M4.21454 15.9189H3.35156V16.7819H4.21454V15.9189Z" fill="#1E1E1E"/>
		<path d="M8.40399 15.9189H7.54102V16.7819H8.40399V15.9189Z" fill="black"/>
		<path d="M10.9167 15.9189H10.0537V16.7819H10.9167V15.9189Z" fill="#1E1E1E"/>
		<path d="M14.2682 15.9189H13.4053V16.7819H14.2682V15.9189Z" fill="#1E1E1E"/>
		<path d="M15.944 15.9189H15.0811V16.7819H15.944V15.9189Z" fill="#1E1E1E"/>
		<path d="M16.7819 15.9189H15.9189V16.7819H16.7819V15.9189Z" fill="#1E1E1E"/>
		<path d="M18.4577 15.9189H17.5947V16.7819H18.4577V15.9189Z" fill="#1E1E1E"/>
		<path d="M19.2956 15.9189H18.4326V16.7819H19.2956V15.9189Z" fill="#1E1E1E"/>
		<path d="M20.1335 15.9189H19.2705V16.7819H20.1335V15.9189Z" fill="#1E1E1E"/>
		<path d="M20.9714 15.9189H20.1084V16.7819H20.9714V15.9189Z" fill="#1E1E1E"/>
		<path d="M22.6472 15.9189H21.7842V16.7819H22.6472V15.9189Z" fill="#1E1E1E"/>
		<path d="M24.322 15.9189H23.459V16.7819H24.322V15.9189Z" fill="#1E1E1E"/>
		<path d="M25.1598 15.9189H24.2969V16.7819H25.1598V15.9189Z" fill="#1E1E1E"/>
		<path d="M25.9977 15.9189H25.1348V16.7819H25.9977V15.9189Z" fill="#1E1E1E"/>
		<path d="M27.6735 15.9189H26.8105V16.7819H27.6735V15.9189Z" fill="#1E1E1E"/>
		<path d="M1.70086 16.7568H0.837891V17.6198H1.70086V16.7568Z" fill="#1E1E1E"/>
		<path d="M3.37664 16.7568H2.51367V17.6198H3.37664V16.7568Z" fill="#1E1E1E"/>
		<path d="M5.89032 16.7568H5.02734V17.6198H5.89032V16.7568Z" fill="#1E1E1E"/>
		<path d="M6.72821 16.7568H5.86523V17.6198H6.72821V16.7568Z" fill="#1E1E1E"/>
		<path d="M8.40399 16.7568H7.54102V17.6198H8.40399V16.7568Z" fill="#1E1E1E"/>
		<path d="M11.7546 16.7568H10.8916V17.6198H11.7546V16.7568Z" fill="#1E1E1E"/>
		<path d="M12.5925 16.7568H11.7295V17.6198H12.5925V16.7568Z" fill="#1E1E1E"/>
		<path d="M13.4304 16.7568H12.5674V17.6198H13.4304V16.7568Z" fill="#1E1E1E"/>
		<path d="M15.944 16.7568H15.0811V17.6198H15.944V16.7568Z" fill="#1E1E1E"/>
		<path d="M21.8093 16.7568H20.9463V17.6198H21.8093V16.7568Z" fill="#1E1E1E"/>
		<path d="M22.6472 16.7568H21.7842V17.6198H22.6472V16.7568Z" fill="#1E1E1E"/>
		<path d="M24.322 16.7568H23.459V17.6198H24.322V16.7568Z" fill="#1E1E1E"/>
		<path d="M25.1598 16.7568H24.2969V17.6198H25.1598V16.7568Z" fill="#1E1E1E"/>
		<path d="M26.8356 16.7568H25.9727V17.6198H26.8356V16.7568Z" fill="#1E1E1E"/>
		<path d="M27.6735 16.7568H26.8105V17.6198H27.6735V16.7568Z" fill="#1E1E1E"/>
		<path d="M0.862973 17.5945H0V18.4575H0.862973V17.5945Z" fill="#1E1E1E"/>
		<path d="M2.53875 17.5945H1.67578V18.4575H2.53875V17.5945Z" fill="#1E1E1E"/>
		<path d="M3.37664 17.5945H2.51367V18.4575H3.37664V17.5945Z" fill="#1E1E1E"/>
		<path d="M4.21454 17.5945H3.35156V18.4575H4.21454V17.5945Z" fill="#1E1E1E"/>
		<path d="M5.05243 17.5945H4.18945V18.4575H5.05243V17.5945Z" fill="#1E1E1E"/>
		<path d="M6.72821 17.5945H5.86523V18.4575H6.72821V17.5945Z" fill="#1E1E1E"/>
		<path d="M8.40399 17.5945H7.54102V18.4575H8.40399V17.5945Z" fill="#1E1E1E"/>
		<path d="M9.2409 17.5945H8.37793V18.4575H9.2409V17.5945Z" fill="#1E1E1E"/>
		<path d="M10.0788 17.5945H9.21582V18.4575H10.0788V17.5945Z" fill="#1E1E1E"/>
		<path d="M10.9167 17.5945H10.0537V18.4575H10.9167V17.5945Z" fill="#1E1E1E"/>
		<path d="M11.7546 17.5945H10.8916V18.4575H11.7546V17.5945Z" fill="#1E1E1E"/>
		<path d="M12.5925 17.5945H11.7295V18.4575H12.5925V17.5945Z" fill="#1E1E1E"/>
		<path d="M17.6198 17.5945H16.7568V18.4575H17.6198V17.5945Z" fill="#1E1E1E"/>
		<path d="M20.9714 17.5945H20.1084V18.4575H20.9714V17.5945Z" fill="#1E1E1E"/>
		<path d="M25.9977 17.5945H25.1348V18.4575H25.9977V17.5945Z" fill="#1E1E1E"/>
		<path d="M27.6735 17.5945H26.8105V18.4575H27.6735V17.5945Z" fill="#1E1E1E"/>
		<path d="M0.862973 18.4324H0V19.2953H0.862973V18.4324Z" fill="#1E1E1E"/>
		<path d="M2.53875 18.4324H1.67578V19.2953H2.53875V18.4324Z" fill="#1E1E1E"/>
		<path d="M3.37664 18.4324H2.51367V19.2953H3.37664V18.4324Z" fill="#1E1E1E"/>
		<path d="M4.21454 18.4324H3.35156V19.2953H4.21454V18.4324Z" fill="#1E1E1E"/>
		<path d="M5.05243 18.4324H4.18945V19.2953H5.05243V18.4324Z" fill="#1E1E1E"/>
		<path d="M5.89032 18.4324H5.02734V19.2953H5.89032V18.4324Z" fill="#1E1E1E"/>
		<path d="M8.40399 18.4324H7.54102V19.2953H8.40399V18.4324Z" fill="#1E1E1E"/>
		<path d="M10.9167 18.4324H10.0537V19.2953H10.9167V18.4324Z" fill="#1E1E1E"/>
		<path d="M14.2682 18.4324H13.4053V19.2953H14.2682V18.4324Z" fill="#1E1E1E"/>
		<path d="M15.944 18.4324H15.0811V19.2953H15.944V18.4324Z" fill="#1E1E1E"/>
		<path d="M17.6198 18.4324H16.7568V19.2953H17.6198V18.4324Z" fill="#1E1E1E"/>
		<path d="M19.2956 18.4324H18.4326V19.2953H19.2956V18.4324Z" fill="#1E1E1E"/>
		<path d="M25.1598 18.4324H24.2969V19.2953H25.1598V18.4324Z" fill="#1E1E1E"/>
		<path d="M25.9977 18.4324H25.1348V19.2953H25.9977V18.4324Z" fill="#1E1E1E"/>
		<path d="M26.8356 18.4324H25.9727V19.2953H26.8356V18.4324Z" fill="#1E1E1E"/>
		<path d="M0.862973 19.2703H0V20.1332H0.862973V19.2703Z" fill="#1E1E1E"/>
		<path d="M5.05243 19.2703H4.18945V20.1332H5.05243V19.2703Z" fill="#1E1E1E"/>
		<path d="M16.7819 19.2703H15.9189V20.1332H16.7819V19.2703Z" fill="#1E1E1E"/>
		<path d="M17.6198 19.2703H16.7568V20.1332H17.6198V19.2703Z" fill="#1E1E1E"/>
		<path d="M18.4577 19.2703H17.5947V20.1332H18.4577V19.2703Z" fill="#1E1E1E"/>
		<path d="M19.2956 19.2703H18.4326V20.1332H19.2956V19.2703Z" fill="#1E1E1E"/>
		<path d="M20.9714 19.2703H20.1084V20.1332H20.9714V19.2703Z" fill="#1E1E1E"/>
		<path d="M24.322 19.2703H23.459V20.1332H24.322V19.2703Z" fill="#1E1E1E"/>
		<path d="M25.1598 19.2703H24.2969V20.1332H25.1598V19.2703Z" fill="#1E1E1E"/>
		<path d="M25.9977 19.2703H25.1348V20.1332H25.9977V19.2703Z" fill="#1E1E1E"/>
		<path d="M27.6735 19.2703H26.8105V20.1332H27.6735V19.2703Z" fill="#1E1E1E"/>
		<path d="M0.862973 20.1082H0V20.9711H0.862973V20.1082Z" fill="#1E1E1E"/>
		<path d="M3.37664 20.1082H2.51367V20.9711H3.37664V20.1082Z" fill="#1E1E1E"/>
		<path d="M4.21454 20.1082H3.35156V20.9711H4.21454V20.1082Z" fill="#1E1E1E"/>
		<path d="M5.05243 20.1082H4.18945V20.9711H5.05243V20.1082Z" fill="#1E1E1E"/>
		<path d="M5.89032 20.1082H5.02734V20.9711H5.89032V20.1082Z" fill="#1E1E1E"/>
		<path d="M6.72821 20.1082H5.86523V20.9711H6.72821V20.1082Z" fill="#1E1E1E"/>
		<path d="M7.5661 20.1082H6.70312V20.9711H7.5661V20.1082Z" fill="#1E1E1E"/>
		<path d="M8.40399 20.1082H7.54102V20.9711H8.40399V20.1082Z" fill="#1E1E1E"/>
		<path d="M11.7546 20.1082H10.8916V20.9711H11.7546V20.1082Z" fill="#1E1E1E"/>
		<path d="M13.4304 20.1082H12.5674V20.9711H13.4304V20.1082Z" fill="#1E1E1E"/>
		<path d="M14.2682 20.1082H13.4053V20.9711H14.2682V20.1082Z" fill="#1E1E1E"/>
		<path d="M15.1061 20.1082H14.2432V20.9711H15.1061V20.1082Z" fill="#1E1E1E"/>
		<path d="M15.944 20.1082H15.0811V20.9711H15.944V20.1082Z" fill="#1E1E1E"/>
		<path d="M18.4577 20.1082H17.5947V20.9711H18.4577V20.1082Z" fill="#1E1E1E"/>
		<path d="M20.9714 20.1082H20.1084V20.9711H20.9714V20.1082Z" fill="#1E1E1E"/>
		<path d="M21.8093 20.1082H20.9463V20.9711H21.8093V20.1082Z" fill="#1E1E1E"/>
		<path d="M22.6472 20.1082H21.7842V20.9711H22.6472V20.1082Z" fill="#1E1E1E"/>
		<path d="M23.485 20.1082H22.6221V20.9711H23.485V20.1082Z" fill="#1E1E1E"/>
		<path d="M24.322 20.1082H23.459V20.9711H24.322V20.1082Z" fill="#1E1E1E"/>
		<path d="M26.8356 20.1082H25.9727V20.9711H26.8356V20.1082Z" fill="#1E1E1E"/>
		<path d="M27.6735 20.1082H26.8105V20.9711H27.6735V20.1082Z" fill="#1E1E1E"/>
		<path d="M7.5661 20.946H6.70312V21.809H7.5661V20.946Z" fill="#1E1E1E"/>
		<path d="M8.40399 20.946H7.54102V21.809H8.40399V20.946Z" fill="#1E1E1E"/>
		<path d="M9.2409 20.946H8.37793V21.809H9.2409V20.946Z" fill="#1E1E1E"/>
		<path d="M10.0788 20.946H9.21582V21.809H10.0788V20.946Z" fill="#1E1E1E"/>
		<path d="M12.5925 20.946H11.7295V21.809H12.5925V20.946Z" fill="#1E1E1E"/>
		<path d="M13.4304 20.946H12.5674V21.809H13.4304V20.946Z" fill="#1E1E1E"/>
		<path d="M20.1335 20.946H19.2705V21.809H20.1335V20.946Z" fill="#1E1E1E"/>
		<path d="M20.9714 20.946H20.1084V21.809H20.9714V20.946Z" fill="#1E1E1E"/>
		<path d="M24.322 20.946H23.459V21.809H24.322V20.946Z" fill="#1E1E1E"/>
		<path d="M27.6735 20.946H26.8105V21.809H27.6735V20.946Z" fill="#1E1E1E"/>
		<path d="M10.0788 21.7837H9.21582V22.6467H10.0788V21.7837Z" fill="#1E1E1E"/>
		<path d="M11.7546 21.7837H10.8916V22.6467H11.7546V21.7837Z" fill="#1E1E1E"/>
		<path d="M12.5925 21.7837H11.7295V22.6467H12.5925V21.7837Z" fill="#1E1E1E"/>
		<path d="M14.2682 21.7837H13.4053V22.6467H14.2682V21.7837Z" fill="#1E1E1E"/>
		<path d="M15.944 21.7837H15.0811V22.6467H15.944V21.7837Z" fill="#1E1E1E"/>
		<path d="M19.2956 21.7837H18.4326V22.6467H19.2956V21.7837Z" fill="#1E1E1E"/>
		<path d="M20.1335 21.7837H19.2705V22.6467H20.1335V21.7837Z" fill="#1E1E1E"/>
		<path d="M20.9714 21.7837H20.1084V22.6467H20.9714V21.7837Z" fill="#1E1E1E"/>
		<path d="M22.6472 21.7837H21.7842V22.6467H22.6472V21.7837Z" fill="#1E1E1E"/>
		<path d="M24.322 21.7837H23.459V22.6467H24.322V21.7837Z" fill="#1E1E1E"/>
		<path d="M25.1598 21.7837H24.2969V22.6467H25.1598V21.7837Z" fill="#1E1E1E"/>
		<path d="M25.9977 21.7837H25.1348V22.6467H25.9977V21.7837Z" fill="#1E1E1E"/>
		<path d="M26.8356 21.7837H25.9727V22.6467H26.8356V21.7837Z" fill="#1E1E1E"/>
		<path d="M8.40399 22.6216H7.54102V23.4846H8.40399V22.6216Z" fill="#1E1E1E"/>
		<path d="M9.2409 22.6216H8.37793V23.4846H9.2409V22.6216Z" fill="#1E1E1E"/>
		<path d="M10.9167 22.6216H10.0537V23.4846H10.9167V22.6216Z" fill="#1E1E1E"/>
		<path d="M11.7546 22.6216H10.8916V23.4846H11.7546V22.6216Z" fill="#1E1E1E"/>
		<path d="M13.4304 22.6216H12.5674V23.4846H13.4304V22.6216Z" fill="#1E1E1E"/>
		<path d="M14.2682 22.6216H13.4053V23.4846H14.2682V22.6216Z" fill="#1E1E1E"/>
		<path d="M16.7819 22.6216H15.9189V23.4846H16.7819V22.6216Z" fill="#1E1E1E"/>
		<path d="M20.1335 22.6216H19.2705V23.4846H20.1335V22.6216Z" fill="#1E1E1E"/>
		<path d="M20.9714 22.6216H20.1084V23.4846H20.9714V22.6216Z" fill="#1E1E1E"/>
		<path d="M24.322 22.6216H23.459V23.4846H24.322V22.6216Z" fill="#1E1E1E"/>
		<path d="M25.1598 22.6216H24.2969V23.4846H25.1598V22.6216Z" fill="#1E1E1E"/>
		<path d="M25.9977 22.6216H25.1348V23.4846H25.9977V22.6216Z" fill="#1E1E1E"/>
		<path d="M26.8356 22.6216H25.9727V23.4846H26.8356V22.6216Z" fill="#1E1E1E"/>
		<path d="M27.6735 22.6216H26.8105V23.4846H27.6735V22.6216Z" fill="#1E1E1E"/>
		<path d="M7.5661 23.4595H6.70312V24.3224H7.5661V23.4595Z" fill="#1E1E1E"/>
		<path d="M8.40399 23.4595H7.54102V24.3224H8.40399V23.4595Z" fill="#1E1E1E"/>
		<path d="M9.2409 23.4595H8.37793V24.3224H9.2409V23.4595Z" fill="#1E1E1E"/>
		<path d="M11.7546 23.4595H10.8916V24.3224H11.7546V23.4595Z" fill="#1E1E1E"/>
		<path d="M12.5925 23.4595H11.7295V24.3224H12.5925V23.4595Z" fill="#1E1E1E"/>
		<path d="M13.4304 23.4595H12.5674V24.3224H13.4304V23.4595Z" fill="#1E1E1E"/>
		<path d="M14.2682 23.4595H13.4053V24.3224H14.2682V23.4595Z" fill="#1E1E1E"/>
		<path d="M16.7819 23.4595H15.9189V24.3224H16.7819V23.4595Z" fill="#1E1E1E"/>
		<path d="M17.6198 23.4595H16.7568V24.3224H17.6198V23.4595Z" fill="#1E1E1E"/>
		<path d="M18.4577 23.4595H17.5947V24.3224H18.4577V23.4595Z" fill="#1E1E1E"/>
		<path d="M19.2956 23.4595H18.4326V24.3224H19.2956V23.4595Z" fill="#1E1E1E"/>
		<path d="M20.9714 23.4595H20.1084V24.3224H20.9714V23.4595Z" fill="#1E1E1E"/>
		<path d="M21.8093 23.4595H20.9463V24.3224H21.8093V23.4595Z" fill="#1E1E1E"/>
		<path d="M22.6472 23.4595H21.7842V24.3224H22.6472V23.4595Z" fill="#1E1E1E"/>
		<path d="M23.485 23.4595H22.6221V24.3224H23.485V23.4595Z" fill="#1E1E1E"/>
		<path d="M24.322 23.4595H23.459V24.3224H24.322V23.4595Z" fill="#1E1E1E"/>
		<path d="M26.8356 23.4595H25.9727V24.3224H26.8356V23.4595Z" fill="#1E1E1E"/>
		<path d="M27.6735 23.4595H26.8105V24.3224H27.6735V23.4595Z" fill="#1E1E1E"/>
		<path d="M7.5661 24.2974H6.70312V25.1603H7.5661V24.2974Z" fill="#1E1E1E"/>
		<path d="M10.9167 24.2974H10.0537V25.1603H10.9167V24.2974Z" fill="#1E1E1E"/>
		<path d="M15.1061 24.2974H14.2432V25.1603H15.1061V24.2974Z" fill="#1E1E1E"/>
		<path d="M16.7819 24.2974H15.9189V25.1603H16.7819V24.2974Z" fill="#1E1E1E"/>
		<path d="M17.6198 24.2974H16.7568V25.1603H17.6198V24.2974Z" fill="#1E1E1E"/>
		<path d="M21.8093 24.2974H20.9463V25.1603H21.8093V24.2974Z" fill="#1E1E1E"/>
		<path d="M24.322 24.2974H23.459V25.1603H24.322V24.2974Z" fill="#1E1E1E"/>
		<path d="M25.1598 24.2974H24.2969V25.1603H25.1598V24.2974Z" fill="#1E1E1E"/>
		<path d="M26.8356 24.2974H25.9727V25.1603H26.8356V24.2974Z" fill="#1E1E1E"/>
		<path d="M7.5661 25.1353H6.70312V25.9982H7.5661V25.1353Z" fill="#1E1E1E"/>
		<path d="M8.40399 25.1353H7.54102V25.9982H8.40399V25.1353Z" fill="#1E1E1E"/>
		<path d="M10.9167 25.1353H10.0537V25.9982H10.9167V25.1353Z" fill="#1E1E1E"/>
		<path d="M12.5925 25.1353H11.7295V25.9982H12.5925V25.1353Z" fill="#1E1E1E"/>
		<path d="M13.4304 25.1353H12.5674V25.9982H13.4304V25.1353Z" fill="#1E1E1E"/>
		<path d="M14.2682 25.1353H13.4053V25.9982H14.2682V25.1353Z" fill="#1E1E1E"/>
		<path d="M15.1061 25.1353H14.2432V25.9982H15.1061V25.1353Z" fill="#1E1E1E"/>
		<path d="M18.4577 25.1353H17.5947V25.9982H18.4577V25.1353Z" fill="#1E1E1E"/>
		<path d="M19.2956 25.1353H18.4326V25.9982H19.2956V25.1353Z" fill="#1E1E1E"/>
		<path d="M20.1335 25.1353H19.2705V25.9982H20.1335V25.1353Z" fill="#1E1E1E"/>
		<path d="M20.9714 25.1353H20.1084V25.9982H20.9714V25.1353Z" fill="#1E1E1E"/>
		<path d="M21.8093 25.1353H20.9463V25.9982H21.8093V25.1353Z" fill="#1E1E1E"/>
		<path d="M22.6472 25.1353H21.7842V25.9982H22.6472V25.1353Z" fill="#1E1E1E"/>
		<path d="M11.7546 25.9729H10.8916V26.8359H11.7546V25.9729Z" fill="#1E1E1E"/>
		<path d="M12.5925 25.9729H11.7295V26.8359H12.5925V25.9729Z" fill="#1E1E1E"/>
		<path d="M14.2682 25.9729H13.4053V26.8359H14.2682V25.9729Z" fill="#1E1E1E"/>
		<path d="M18.4577 25.9729H17.5947V26.8359H18.4577V25.9729Z" fill="#1E1E1E"/>
		<path d="M23.485 25.9729H22.6221V26.8359H23.485V25.9729Z" fill="#1E1E1E"/>
		<path d="M25.1598 25.9729H24.2969V26.8359H25.1598V25.9729Z" fill="#1E1E1E"/>
		<path d="M9.2409 26.8108H8.37793V27.6738H9.2409V26.8108Z" fill="#1E1E1E"/>
		<path d="M10.0788 26.8108H9.21582V27.6738H10.0788V26.8108Z" fill="#1E1E1E"/>
		<path d="M10.9167 26.8108H10.0537V27.6738H10.9167V26.8108Z" fill="#1E1E1E"/>
		<path d="M13.4304 26.8108H12.5674V27.6738H13.4304V26.8108Z" fill="#1E1E1E"/>
		<path d="M15.1061 26.8108H14.2432V27.6738H15.1061V26.8108Z" fill="#1E1E1E"/>
		<path d="M15.944 26.8108H15.0811V27.6738H15.944V26.8108Z" fill="#1E1E1E"/>
		<path d="M17.6198 26.8108H16.7568V27.6738H17.6198V26.8108Z" fill="#1E1E1E"/>
		<path d="M18.4577 26.8108H17.5947V27.6738H18.4577V26.8108Z" fill="#1E1E1E"/>
		<path d="M20.1335 26.8108H19.2705V27.6738H20.1335V26.8108Z" fill="#1E1E1E"/>
		<path d="M20.9714 26.8108H20.1084V27.6738H20.9714V26.8108Z" fill="#1E1E1E"/>
		<path d="M21.8093 26.8108H20.9463V27.6738H21.8093V26.8108Z" fill="#1E1E1E"/>
		<path d="M22.6472 26.8108H21.7842V27.6738H22.6472V26.8108Z" fill="#1E1E1E"/>
		<path d="M25.9977 26.8108H25.1348V27.6738H25.9977V26.8108Z" fill="#1E1E1E"/>
		<path d="M26.8356 26.8108H25.9727V27.6738H26.8356V26.8108Z" fill="#1E1E1E"/>
		<path d="M0 0V5.86486H5.86486V0H0ZM5.02703 5.02703H0.837838V0.837838H5.02703V5.02703Z" fill="#1E1E1E"/>
		<path d="M21.7842 0V5.86486H27.649V0H21.7842ZM26.8112 5.02703H22.622V0.837838H26.8112V5.02703Z" fill="#1E1E1E"/>
		<path d="M0 21.7837V27.6486H5.86486V21.7837H0ZM5.02703 26.8107H0.837838V22.6215H5.02703V26.8107Z" fill="#1E1E1E"/>
		<path d="M4.18929 1.67578H1.67578V4.18929H4.18929V1.67578Z" fill="#1E1E1E"/>
		<path d="M25.9725 1.67578H23.459V4.18929H25.9725V1.67578Z" fill="#1E1E1E"/>
		<path d="M4.18929 23.4595H1.67578V25.973H4.18929V23.4595Z" fill="#1E1E1E"/>
	</svg>
);

export default Qr_code_black;