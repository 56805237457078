import styles from './modal.module.scss';

const Modal = ({item, onClose}) => {
	return (
		<div className={styles.Modal_root} onClick={() => onClose()}>
			<div className={styles.Modal_header}>
				{item.collectionLogoSrc && (
					<img className={styles.Modal_logo} src={item.collectionLogoSrc} alt=""/>
				)}
				<p className={styles.Modal_text}>{item.text}</p>
			</div>

			<img className={styles.Modal_poster}  src={item?.posterSrc} alt="poster"/>
		</div>
	)
};

export default Modal;