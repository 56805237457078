// import "./styles.css";
import * as React from "react";
import { AnimatePresence } from "framer-motion";
import { useLocation, useRoutes } from "react-router-dom";
import MainPage from './pages/Main';
import AboutPage from './pages/About';
import PostersPage from './pages/Posters';
// import CollaborationsPage from './pages/Collaborations';
import './styles/reset.scss';

export default function App() {
	const element = useRoutes([
		{
			path: "/",
			element: <MainPage />
		},
		{
			path: "/about",
			element: <AboutPage/>
		},
		{
			path: "/posters/all",
			element: <PostersPage/>
		}
	]);

	const location = useLocation();

	if (!element) return null;

	return (
		<AnimatePresence mode="wait">
			{React.cloneElement(element, { key: location.pathname })}
		</AnimatePresence>
	);
}
