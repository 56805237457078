// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { useLocation, useRoutes } from "react-router-dom";
// import {createBrowserRouter, RouterProvider} from 'react-router-dom';
// import { AnimatePresence } from "framer-motion";
//
// import MainPage from './pages/Main';
// import AboutPage from './pages/About';
// import PostersPage from './pages/Posters';
// import CollaborationsPage from './pages/Collaborations';
// import './styles/reset.scss';
//
// const router = createBrowserRouter([
//   {path: "/", element: <MainPage />},
//   {path: "/about", element: <AboutPage/>},
//   {path: "/posters/all", element: <PostersPage/>},
//   {path: "/collaborations", element: <CollaborationsPage/>},
// ]);
//
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <AnimatePresence>
//       <RouterProvider router={router} />
//     </AnimatePresence>
//   </React.StrictMode>
// );

import { StrictMode } from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

function AppWithUI() {
  return (
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  );
}

const rootElement = document.getElementById("root");
const root = ReactDOMClient.createRoot(rootElement);
root.render(<AppWithUI />);

