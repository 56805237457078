const Logo_white = (props) => (
	<svg {...props} width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<line x1="1" y1="4.37115e-08" x2="0.999999" y2="32" stroke="white" stroke-width="2"/>
		<line x1="5" y1="18" x2="5" y2="24" stroke="white" stroke-width="2"/>
		<line x1="21" y1="18" x2="21" y2="24" stroke="white" stroke-width="2"/>
		<line x1="25" y1="4.37115e-08" x2="25" y2="32" stroke="white" stroke-width="2"/>
		<line y1="1" x2="26" y2="1" stroke="white" stroke-width="2"/>
		<line y1="31" x2="26" y2="31" stroke="white" stroke-width="2"/>
		<line x1="4" y1="27" x2="22" y2="27" stroke="white" stroke-width="2"/>
		<line x1="4" y1="23" x2="22" y2="23" stroke="white" stroke-width="2"/>
		<line x1="4" y1="19" x2="22" y2="19" stroke="white" stroke-width="2"/>
		<line x1="8" y1="15" x2="14" y2="15" stroke="white" stroke-width="2"/>
		<line x1="9" y1="16" x2="9" y2="12" stroke="white" stroke-width="2"/>
		<line x1="13" y1="16" x2="13" y2="12" stroke="white" stroke-width="2"/>
		<line x1="8" y1="15" x2="14" y2="15" stroke="white" stroke-width="2"/>
		<line x1="6" y1="9" x2="14" y2="9" stroke="white" stroke-width="2"/>
		<line x1="16" y1="9" x2="22" y2="9" stroke="white" stroke-width="2"/>
		<line x1="16" y1="15" x2="22" y2="15" stroke="white" stroke-width="2"/>
		<line x1="5" y1="4" x2="5" y2="16" stroke="white" stroke-width="2"/>
		<line x1="17" y1="16" x2="17" y2="8" stroke="white" stroke-width="2"/>
		<line x1="4" y1="5" x2="18" y2="5" stroke="white" stroke-width="2"/>
		<line x1="21" y1="16" x2="21" y2="4" stroke="white" stroke-width="2"/>
	</svg>

);

export default Logo_white;