// import HL_01 from '../../assets/posters/Hello_Lucy/HL_01.jpg';
// import HL_02 from '../../assets/posters/Hello_Lucy/HL_02.jpg';
// import HL_03 from '../../assets/posters/Hello_Lucy/HL_03.jpg';

// neoclassicism
import NC_01 from '../../assets/posters/Neoclassicism/neoclassicism_1.jpg';
import NC_02 from '../../assets/posters/Neoclassicism/neoclassicism_2.jpg';
import NC_03 from '../../assets/posters/Neoclassicism/neoclassicism_3.jpg';
import NC_04 from '../../assets/posters/Neoclassicism/neoclassicism_4.jpg';
import NC_05 from '../../assets/posters/Neoclassicism/neoclassicism_5.jpg';

// Illicit
import IL_01 from '../../assets/posters/Illicit/ILLCT_01.jpg';
import IL_02 from '../../assets/posters/Illicit/ILLCT_02.jpg';
import IL_03 from '../../assets/posters/Illicit/ILLCT_03.jpg';

// New_JDM
import JDM_01 from '../../assets/posters/New_JDM/JDM_01.jpg';
import JDM_02 from '../../assets/posters/New_JDM/JDM_02.jpg';
import JDM_03 from '../../assets/posters/New_JDM/JDM_003.jpg';

// Psilocobalt
import PC_01 from '../../assets/posters/Psilocobalt/PSLCBLT_01.jpg';
import PC_02 from '../../assets/posters/Psilocobalt/PSLCBLT_04.jpg';
import PC_03 from '../../assets/posters/Psilocobalt/PSLCBLT_05.jpg';

// Planetoid
import PL_01 from '../../assets/posters/Planetoid/PL_1.jpg';
import PL_02 from '../../assets/posters/Planetoid/PL_2.jpg';
import PL_03 from '../../assets/posters/Planetoid/PL_3.jpg';

// Logos
// import ILLogo from '../../assets/collections_logo/ILLICIT.svg';
// import JDMLogo from '../../assets/collections_logo/NEW_JDM.svg';
// import PCLogo from '../../assets/collections_logo/PSILOCOBALT.svg';

const posterItems = [
	{
		posterSrc: NC_01,
		collectionLogoSrc: null,
		text: 'Neoclassicism collection #001'
	},
	{
		posterSrc: NC_05,
		collectionLogoSrc: null,
		text: 'Neoclassicism collection #002'
	},
	{
		posterSrc: NC_04,
		collectionLogoSrc: null,
		text: 'Neoclassicism collection #003'
	},
	// {
	// 	posterSrc: NC_02,
	// 	collectionLogoSrc: null,
	// 	text: 'Neoclassicism collection #002'
	// },
	// {
	// 	posterSrc: NC_03,
	// 	collectionLogoSrc: null,
	// 	text: 'Neoclassicism collection #003'
	// },
	{
		posterSrc: IL_01,
		collectionLogoSrc: null,
		text: 'Illicit collection #001'
	},
	{
		posterSrc: IL_02,
		collectionLogoSrc: null,
		text: 'Illicit collection #002'
	},
	{
		posterSrc: IL_03,
		collectionLogoSrc: null,
		text: 'Illicit collection #003'
	},
	{
		posterSrc: JDM_01,
		collectionLogoSrc: null,
		text: 'JDM collection #001'
	},
	{
		posterSrc: JDM_02,
		collectionLogoSrc: null,
		text: 'JDM collection #002'
	},
	{
		posterSrc: JDM_03,
		collectionLogoSrc: null,
		text: 'JDM collection #003'
	},
	{
		posterSrc: PL_03,
		collectionLogoSrc: null,
		text: 'Planetoid collection #003'
	},
	{
		posterSrc: PL_01,
		collectionLogoSrc: null,
		text: 'Planetoid collection #001'
	},
	{
		posterSrc: PL_02,
		collectionLogoSrc: null,
		text: 'Planetoid collection #002'
	},
	{
		posterSrc: PC_01,
		collectionLogoSrc: null,
		text: 'Psilocobalt collection #001'
	},
	{
		posterSrc: PC_02,
		collectionLogoSrc: null,
		text: 'Psilocobalt collection #002'
	},
	{
		posterSrc: PC_03,
		collectionLogoSrc: null,
		text: 'Psilocobalt collection #003'
	},
];

export default posterItems;