
const visibleStage1 = { opacity: 1, transition: { duration: 0.5 } };
const visibleStage2 = { opacity: 1, transition: { duration: 2} };
const visibleStage3 = { opacity: 1, transition: { duration: 4} };
const visibleText = { opacity: 1, y: 0, transition: { duration: 0.5 } };
const visibleTextStage2 = { opacity: 1, y: 0, transition: { duration: 1.5 } };
const visibleTextStage3 = { opacity: 1, y: 0, transition: { duration: 3 } };

const itemVariantsStage1 = {
	hidden: { opacity: 0 },
	visible: visibleStage1
};
const itemVariantsStage2 = {
	hidden: { opacity: 0 },
	visible: visibleStage2
};
const itemVariantsStage3 = {
	hidden: { opacity: 0 },
	visible: visibleStage3
};
const itemVariantsText = {
	hidden: { opacity: 0, y: 10 },
	visible: visibleText
};
const itemVariantsTextStage2 = {
	hidden: { opacity: 0, y: 10 },
	visible: visibleTextStage2
};

const itemVariantsTextStage3 = {
	hidden: { opacity: 0, y: 10 },
	visible: visibleTextStage3
};

export {
	itemVariantsStage1,
	itemVariantsStage2,
	itemVariantsStage3,
	itemVariantsText,
	itemVariantsTextStage2,
	itemVariantsTextStage3
}