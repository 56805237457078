import { motion } from "framer-motion";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from '../../components/Menu';
import styles from './main.module.scss';

import Qr_code_black from "./assets/qr_code_black";
import Qr_code_white from "./assets/qr_code_white";
import TLC from "./assets/top_left_corner";
import Logo_white from "./assets/logo_white";
import Menu_icon from "./assets/menu_icon";
import Icons_couple from "./assets/icons_couple";

import P from './assets/p_white.png';
import bg from './assets/visual_bg.png';

import {
	itemVariantsStage1,
	itemVariantsStage2,
	itemVariantsStage3,
	itemVariantsText
} from '../../animationHelper';

const Main = () => {
	const [isOpen, setOpenMenu] = useState(false);

	return (
		<>
			{isOpen && (
				<Menu closeHandler={() => setOpenMenu(false)} />
			)}

			<motion.div
				initial="hidden"
				animate="visible"
				exit={{ opacity: 0 }}
				className={styles.MainPage_root}
			>
				<motion.div
					variants={itemVariantsStage1}
					className={styles.MainPage_visual}
				>
					<Link to="/">
						<Logo_white className={styles.MainPage_visual__logo}/>
					</Link>
					<Menu_icon className={styles.MainPage_visual__menu} onClick={() => setOpenMenu(true)}/>
					<Icons_couple className={styles.MainPage_visual__icons}/>
					<Qr_code_white className={styles.MainPage_visual__qr}/>
					<motion.img variants={itemVariantsStage3} src={P} alt="p_logo" className={styles.MainPage_visual__p}/>
					<motion.img variants={itemVariantsStage2} src={bg} alt="bg" className={styles.MainPage_visual__bg}/>
				</motion.div>

				<motion.div
					variants={{
						hidden: { opacity: 0 },
						visible: { opacity: 1 }
					}}
					className={styles.MainPage_info}
				>
					{/* corners block */}
					<TLC className={styles.MainPage_info__tlc}/>
					<TLC className={styles.MainPage_info__trc}/>
					<TLC className={styles.MainPage_info__brc}/>
					{/* qr code */}
					<Qr_code_black className={styles.MainPage_info__qr}/>
					{/*	page title */}
					<motion.div variants={itemVariantsText} className={styles.MainPage_info__title}>FUDO</motion.div>
					{/* page text	*/}
					<motion.div variants={itemVariantsStage2} className={styles.MainPage_info__text}>
						Цифровая лаборатория элементов <br/>
						современного искусства
					</motion.div>
					{/* footer text block	*/}
					<motion.div variants={itemVariantsStage1} className={styles.MainPage_info__footerText}>
						<motion.div variants={itemVariantsText} className={styles.MainPage_info__footerTextItem}>
							<p>Instagram</p>
							<a href="https://instagram.com/fudo.style">fudo.style</a>
						</motion.div>
						<motion.div variants={itemVariantsText} className={styles.MainPage_info__footerTextItem}>
							<p>Telegram</p>
							<a href="https://t.me/ivan_fudo">@ivan_fudo</a>
						</motion.div>
					</motion.div>
				</motion.div>
			</motion.div>
		</>
	);
};

export default Main;