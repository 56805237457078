import { Link } from 'react-router-dom';
import styles from './menu.module.scss';

import Logo_white from "../../pages/Main/assets/logo_white";
import Menu_icon_close from "../../pages/Main/assets/menu_icon_close";

const Menu = ({
	closeHandler
}) => {
	console.log(window.location.pathname);

	const closeMenuHandler = (path) => {
		if (path === window.location.pathname) {
			closeHandler();
		}
	}

	return (
		<div className={styles.Menu_root}>
			<Link to="/" className={styles.Menu_logo}>
				<Logo_white/>
			</Link>
			<Menu_icon_close className={styles.Menu_close} onClick={() => closeHandler()}/>
			<div className={styles.Menu_footerText}>
				<div className={styles.Menu_footerTextItem}>
					<p>Instagram</p>
					<a href="https://instagram.com/fudo.style">fudo.style</a>
				</div>
				<div className={styles.Menu_footerTextItem}>
					<p>Telegram</p>
					<a href="https://t.me/ivan_fudo">@ivan_fudo</a>
				</div>
			</div>
			<div className={styles.Menu_items}>
				{window.location.pathname !== '/' && (
					<Link to="/" onClick={() => closeMenuHandler("/")}>
						Главная
					</Link>
				)}
				<Link to="/posters/all" onClick={() => closeMenuHandler("/posters/all")}>
					Постеры
				</Link>
				{/*<Link to="/collaborations" onClick={() => closeMenuHandler("/collaborations")}>*/}
				{/*	Коллабы*/}
				{/*</Link>*/}
				<Link to="/about" onClick={() => closeMenuHandler("/about")}>
					На связи
				</Link>
			</div>
		</div>
	);
};

export default Menu;