import { motion } from "framer-motion";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from '../../components/Menu';
import styles from './about.module.scss';

import Qr_code_black from "./assets/qr_code_black";
import Qr_code_white from "./assets/qr_code_white";
import TLC from "./assets/top_left_corner";
import Logo_white from "./assets/logo_white";
import Menu_icon from "./assets/menu_icon";
import Icons_couple from "./assets/icons_couple";
import bg from './assets/face_bg.png';

import {
	itemVariantsStage2,
	itemVariantsTextStage2,
} from '../../animationHelper';


const About = () => {
	const [isOpen, setOpenMenu] = useState(false);

	return (
		<>
			{isOpen && (
				<Menu closeHandler={() => setOpenMenu(false)} />
			)}

			<motion.div
				initial="hidden"
				animate="visible"
				exit={{ opacity: 0 }}
				className={styles.AboutPage_root}
			>
				<div className={styles.AboutPage_visual}>
					<Link to="/">
						<Logo_white className={styles.AboutPage_visual__logo}/>
					</Link>
					<Menu_icon className={styles.AboutPage_visual__menu} onClick={() => setOpenMenu(true)}/>
					<Icons_couple className={styles.AboutPage_visual__icons}/>
					<Qr_code_white className={styles.AboutPage_visual__qr}/>
					<motion.img variants={itemVariantsStage2}  src={bg} alt="bg" className={styles.AboutPage_visual__bg}/>
				</div>

				<motion.div variants={itemVariantsStage2} className={styles.AboutPage_info}>
					{/* corners block */}
					<TLC className={styles.AboutPage_info__tlc}/>
					<TLC className={styles.AboutPage_info__trc}/>
					<TLC className={styles.AboutPage_info__brc}/>
					{/* qr code */}
					<Qr_code_black className={styles.AboutPage_info__qr}/>
					{/*	page title */}
					<div className={styles.AboutPage_info__title}>
						<motion.span variants={itemVariantsTextStage2}>Иван</motion.span>
						<motion.p variants={itemVariantsTextStage2}>FUDO</motion.p>
					</div>
					{/* page text	*/}
					<motion.div variants={itemVariantsTextStage2} className={styles.AboutPage_info__text}>
						Открыт к коммерческим предложениям.<br/>
						Хочешь постер? Пиши в <a href="https://t.me/ivan_fudo">телегу</a>.
					</motion.div>
					{/* footer text block	*/}
					<motion.div className={styles.AboutPage_info__footerText}>
						<motion.div variants={itemVariantsTextStage2} className={styles.AboutPage_info__footerTextItem}>
							<p>Instagram</p>
							<a href="https://instagram.com/fudo.style">fudo.style</a>
						</motion.div>
						<motion.div variants={itemVariantsTextStage2} className={styles.AboutPage_info__footerTextItem}>
							<p>Telegram</p>
							<a href="https://t.me/ivan_fudo">@ivan_fudo</a>
						</motion.div>
					</motion.div>
				</motion.div>
			</motion.div>
		</>
	);
};

export default About;