import { motion } from "framer-motion";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from '../../components/Menu';
import Modal from '../../components/Modal';
import styles from './posters.module.scss';

import Logo_white from "./assets/logo_white";
import Menu_icon from "./assets/menu_icon";
import bg from './assets/visual_bg.png';

import posterItems from './items';
import {
	itemVariantsStage2,
} from '../../animationHelper';

const Posters = () => {
	const [isOpen, setOpenMenu] = useState(false);
	const [activeItem, setActiveItem] = useState(null);

	return (
		<>
			{isOpen && (
				<Menu closeHandler={() => setOpenMenu(false)} />
			)}
			{activeItem && (
				<Modal item={activeItem} onClose={() => setActiveItem(null)}/>
			)}
			<motion.div
				initial="hidden"
				animate="visible"
				exit={{ opacity: 0 }}
				className={styles.PostersPage_root}
			>
				<motion.div variants={itemVariantsStage2} className={styles.PostersPage_visual}>
					<Link to="/">
						<Logo_white className={styles.PostersPage_visual__logo}/>
					</Link>
					<Menu_icon className={styles.PostersPage_visual__menu} onClick={() => setOpenMenu(true)}/>
					<img loading="eager" src={bg} alt="bg" className={styles.PostersPage_visual__bg}/>
				</motion.div>

				<div className={styles.PostersPage_info}>
					<motion.div variants={itemVariantsStage2} className={styles.PostersPage_info__content}>
						{posterItems.map((item, idx) => {
							return (
								<div
									key={item.text}
									className={styles.PostersPage_info__contentItem}
									onClick={() => setActiveItem(item)}
								>
									<img
										key={`${item.text}_img`}
										src={item.posterSrc}
										alt="poster"
									/>
								</div>
							)
						})}
					</motion.div>
					{/* footer text block	*/}
					<div className={styles.PostersPage_info__footerText}>
						<div className={styles.PostersPage_info__footerTextItem}>
							<p>Instagram</p>
							<a href="https://instagram.com/fudo.style">fudo.style</a>
						</div>
						<div className={styles.PostersPage_info__footerTextItem}>
							<p>Telegram</p>
							<a href="https://t.me/ivan_fudo">@ivan_fudo</a>
						</div>
					</div>
				</div>
			</motion.div>
		</>
	);
};

export default Posters;